/* eslint-disable func-names */

import { Utility } from "src/helpers/utility";
import store from "src/redux/store";

const blacklistedPartners = ["School", "ApplicationPartner"];

const initializeSegment = user => {
  window.clevertap?.onUserLogin.push({
    Site: {
      Email: user.email,
      Identity: user.id,
    },
  });
};

const initializeClevertap = user => {
  if (window?.clarity) {
    const userId = user.id.toString();
    window.clarity("identify", userId);
    window.clarity("set", "userId", userId);
    window.clarity("set", "email", user.email);
    window.clarity("set", "university", user.company?.name);
  }

  window.analytics?.identify(user.id, {
    name: `${user.first_name} ${user.last_name}`,
    email: user.email,
    company: user.company,
  });
};

const isTrackingAllowed = user => {
  const isBlackListedPartner = blacklistedPartners.includes(
    user?.institute_type,
  );
  const isSupportUser = user?.is_support_user;
  return !(isBlackListedPartner || isSupportUser);
};

const initialize = user => {
  initializeSegment(user);
  initializeClevertap(user);
};

const trackUser = (eventName, payload) => {
  if (window?.clarity) {
    const state = store.getState();
    const user = state.user.data;
    if (user?.id) {
      const userId = user.id.toString();
      window.clarity("set", "userId", userId);
      window.clarity("set", "email", user.email);
      window.clarity("set", "university", user.company?.name);
    }
    window.clarity("event", eventName);
  }
  return window.analytics?.track(eventName, payload);
};

const doNotTrackUser = Utility.doNothing;

const Analytics = function () {
  // do nothing
};

Analytics.prototype.load = function (key) {
  window.analytics?.load(key);
};

Analytics.prototype.track = doNotTrackUser;

Analytics.prototype.trackPublic = trackUser;

Analytics.prototype.shouldTrackUser = function (user) {
  if (isTrackingAllowed(user)) {
    initialize(user);
    Analytics.prototype.track = trackUser;
  } else {
    Analytics.prototype.track = doNotTrackUser;
  }
};

export default new Analytics();
