import { INTERCOM_APP_ID } from "../config";

const bootIntercom = ({ isAuthenticated, user }) => {
  if (!window.Intercom) {
    return;
  }

  let intercomPayload = {
    app_id: INTERCOM_APP_ID,
    custom_launcher_selector: "#intercom-switch",
  };

  if (isAuthenticated && user) {
    intercomPayload = {
      ...intercomPayload,
      email: user.email,
      first_name: user.first_name,
      last_name: user.last_name,
      institute_type: user.institute_type,
      hide_default_launcher: user.institute_type === "School",
      company: {
        name: user.company.name,
        id: `Explore-${user.company.id}`,
        created_at: user.company.created_at,
      },
    };
  } else {
    intercomPayload = {
      ...intercomPayload,
      hide_default_launcher: false,
      email: null,
    };
  }

  window.Intercom("boot", intercomPayload);
};

const hideIntercom = () => {
  if (!window.Intercom) {
    return;
  }

  window.Intercom("update", { hide_default_launcher: true });
};

const showIntercom = () => {
  if (!window.Intercom) {
    return;
  }

  window.Intercom("update", { hide_default_launcher: false });
};

export { bootIntercom, hideIntercom, showIntercom };
